import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { SelectItemSearch } from '@/core/SelectItemSearch.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { SelectedSearchItem } from '@/search/search.types';

export interface SelectItemSearchFormComponentProps extends ValidatingFormComponent<SelectedSearchItem[]> {
  component: 'SelectItemSearchFormComponent';
  iconPartialTooltipKey: string;
  scopeIds?: string[];
  excludeGloballyScoped?: boolean;
  helpTextKey?: string;
  customErrorText?: string;
  title: string;
  notSelectedMessage: string;
  searchTypes?: string[];
  /** determines if the component will handle single or multiple item selection in the modal*/
  isMultiple?: boolean;
  showAddIcon?: boolean;
  canSelectAll?: boolean;
  showPinnedAssets?: boolean;
  disableInput?: boolean;
  showOnlyConditions?: boolean;
  showOnlyScopedAssets?: boolean;
  showSwitchToLocallyScoped?: boolean;
  onSwitchToLocallyScoped?: () => void;
  showModal?: boolean;
  setShowModal?: (value: boolean) => void;
  validateItem?: (item: SelectedSearchItem) => Promise<boolean>;
  filterByWorkbook?: boolean;
}

const DEFAULT_SEARCH_TYPES = [SeeqNames.Types.Asset];

const defaultItemValidation = () => Promise.resolve(true);

export const SelectItemSearchFormComponent: React.FunctionComponent<SelectItemSearchFormComponentProps> = (props) => {
  const {
    name,
    //currently selected items in the form after save is clicked in the modal
    value,
    testId = 'selectAsset',
    validation,
    extendValidation,
    iconPartialTooltipKey,
    helpTextKey = undefined,
    customErrorText,
    scopeIds,
    excludeGloballyScoped = false,
    title,
    notSelectedMessage,
    searchTypes = DEFAULT_SEARCH_TYPES,
    isMultiple = false,
    showAddIcon = false,
    required = true,
    canSelectAll = false,
    showPinnedAssets = false,
    disableInput = false,
    showOnlyConditions = false,
    showOnlyScopedAssets = false,
    showSwitchToLocallyScoped = false,
    onSwitchToLocallyScoped = _.noop,
    showModal = false,
    setShowModal = _.noop,
    validateItem = defaultItemValidation,
    filterByWorkbook,
  } = props;
  const { t } = useTranslation();

  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    (_.has(formState.dirtyFields, name) || _.has(formState.dirtyFieldsSinceLastSubmit, name)) &&
    formState.hasValidationErrors;

  const defaultValidation = (value: SelectedSearchItem[]) => required && !value;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <FormFieldWrapper
      wrapperClassNames="flexFill"
      testId={testId}
      showError={showError}
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation} value={value}>
        {({ input, meta }) => {
          const { onChange, value, extraClassNames } = getFormFieldProps(formState, input, meta, props);

          return (
            <SelectItemSearch
              testId={testId}
              value={value}
              onChange={onChange}
              isMultiple={isMultiple}
              notSelectedMessage={notSelectedMessage}
              showModal={showModal}
              onModalClose={() => setShowModal(false)}
              showAddIcon={showAddIcon}
              isDisabled={disableInput}
              extraValueClassNames={extraClassNames}
              modalProps={{
                selectionType: 'Other',
                iconPartialTooltipKey,
                scopeIds,
                excludeGloballyScoped,
                searchTypes,
                showPinnedAssets,
                showOnlyConditions,
                showOnlyScopedAssets,
                showSwitchToLocallyScoped,
                onSwitchToLocallyScoped,
                validateItem,
                header: { title: t(title) },
                canSelectAll,
                helpTextKey,
                filterByWorkbook,
              }}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
