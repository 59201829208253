import React from 'react';
import { LegendWrapper } from '@/trend/LegendWrapper.atom';
import {
  TrendColorSettings,
  UsedCapsulePropertyValues,
  UserCapsulePropertyColorSettings,
} from '@/trendData/trendData.constants';
import { Icon } from '@seeqdev/qomponents';

interface ColorByCapsulePropertyLegendProps {
  trendColorSettings: TrendColorSettings;
  trendCapsulePropertyColors: UserCapsulePropertyColorSettings;
  usedCapsulePropertyValues: UsedCapsulePropertyValues;
}

export const ColorByCapsulePropertyLegend: React.FunctionComponent<ColorByCapsulePropertyLegendProps> = ({
  trendColorSettings,
  trendCapsulePropertyColors,
  usedCapsulePropertyValues,
}) => {
  return (
    <LegendWrapper title={trendColorSettings.colorByCapsuleProperty}>
      {usedCapsulePropertyValues
        .slice()
        .sort()
        .map((value) => {
          const color = trendCapsulePropertyColors[trendColorSettings.colorByCapsuleProperty!]?.[value];
          return (
            <div className="mr10 text-nowrap" key={value}>
              <Icon icon="fc-capsule" extraClassNames="pr2" type={color ? 'color' : undefined} color={color} />
              {value}
            </div>
          );
        })}
    </LegendWrapper>
  );
};
