// @ts-strict-ignore
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { CheckMarkOrNumber } from '@/core/CheckMarkOrNumber.atom';
import { AdvancedParameters } from '@/core/AdvancedParameters.atom';
import { FakeLink } from '@/core/FakeLink';

export const FormCondition = ({ conditionName, children, show }) => (
  <Field name={conditionName} subscription={{ value: true }} key={`${conditionName}_field`}>
    {() => (show ? children : null)}
  </Field>
);

export const ConditionalFormBlock = ({ isConditional, show, name, children }) => {
  if (isConditional) {
    return (
      <FormCondition conditionName={`${name}_condition`} show={show} key={`${name}_condition`}>
        {children}
      </FormCondition>
    );
  }
  return <React.Fragment key={`${name}_conditionBlock`}>{children}</React.Fragment>;
};

export const FormBlockWithCheckmarkAndNumber = ({ name, number, fieldNames, children, extraClassNames }) => {
  return (
    <div
      className={classNames('flexColumnContainer formElement mb10 mt15 ml5 mr5', extraClassNames)}
      key={name}
      id={`${name}-wrapper`}>
      <FormSpy subscription={{ errors: true, values: true }} key={`${name}_spy`}>
        {({ errors }) => {
          const valid = _.reduce(fieldNames, (acc, cur) => acc && !errors[cur], true);
          return <CheckMarkOrNumber showNumber={!valid} number={number} />;
        }}
      </FormSpy>
      {children}
    </div>
  );
};

export const FormRowBlock = ({ name, testId, extraClassNames, children }) => (
  <div
    className={classNames('flexColumnContainer', extraClassNames)}
    key={`${name}_row`}
    id={name}
    data-testid={testId}>
    {children}
  </div>
);

export const RemovableFormRowBlock = ({
  name,
  testId,
  extraClassNames,
  wrapperClassNames,
  children,
  hideRemoveIcon,
  removeAction,
}) => (
  <div className={classNames('removableRowWrapper', wrapperClassNames)} data-testid={`${testId}_wrapper`}>
    {!hideRemoveIcon && (
      <Icon icon="fa-close" testId="removeIcon" extraClassNames="removeIcon" onClick={removeAction} />
    )}
    <div
      className={classNames('flexColumnContainer', extraClassNames)}
      key={`${name}_row`}
      id={name}
      data-testid={testId}>
      {children}
    </div>
  </div>
);

export const AdvancedFormBlock = ({
  name,
  testId,
  toolName,
  toolId,
  toolStore,
  alternateName,
  extraClassNames,
  iconStyle,
  children,
}) => (
  <div
    className={classNames('flexRowContainer advancedPanel toolOptions mb15', extraClassNames)}
    key={`${name}_wrapper`}
    data-testid={testId}
    id={name}>
    <AdvancedParameters
      toolName={toolName}
      toolId={toolId}
      toolStore={toolStore}
      alternateName={alternateName}
      iconStyle={iconStyle}>
      {children}
    </AdvancedParameters>
  </div>
);

export const FormGroupBlock = ({ name, testId, extraClassNames, showBracket, children, showScroll }) => (
  <div
    className={classNames('flexRowContainer', showScroll ? 'flexFill' : 'flexFillOverflow', extraClassNames, {
      leftBracket: showBracket,
    })}
    key={`${name}_wrapper`}
    data-testid={testId || name}
    id={name}>
    {children}
  </div>
);

interface FormGroupWithTitleBlockProps {
  name: string;
  testId?: string;
  extraClassNames?: string;
  showBracket?: boolean;
  children: React.ReactNode;
  showScroll?: boolean;
  showTitle?: boolean;
  title?: string;
  titleLink?: string;
  titleLinkAction?: () => void;
  additionalTitleLink?: string;
  additionalTitleLinkAction?: () => void;
}

export const FormGroupWithTitleBlock: React.FunctionComponent<FormGroupWithTitleBlockProps> = ({
  name,
  testId,
  extraClassNames,
  showBracket,
  children,
  showScroll,
  showTitle,
  title,
  titleLink,
  titleLinkAction,
  additionalTitleLink,
  additionalTitleLinkAction,
}) => (
  <div
    className={classNames('flexRowContainer', showScroll ? 'flexFill' : 'flexFillOverflow', extraClassNames, {
      leftBracket: showBracket,
    })}
    key={`${name}_wrapper`}
    data-testid={testId || name}
    id={name}>
    {showTitle && (
      <div className="formGroupTitle grayBorder">
        {title}
        {titleLink && (
          <FakeLink onClick={titleLinkAction} extraClassNames="formGroupTitleLink link-underline-hover">
            {titleLink}
          </FakeLink>
        )}
        {additionalTitleLink && (
          <FakeLink
            onClick={additionalTitleLinkAction}
            extraClassNames="formGroupAdditionalTitleLink link-underline-hover">
            {additionalTitleLink}
          </FakeLink>
        )}
      </div>
    )}
    {children}
  </div>
);

interface RemovableFormBlockProps {
  testId?: string;
  children: React.ReactNode;
  iconAction: () => void;
  hideIcon?: boolean;
  extraClassNames?: string;
  iconHasMargin?: boolean;
  iconTooltip?: string;
}

export const RemovableFormBlock: React.FunctionComponent<RemovableFormBlockProps> = ({
  testId,
  children,
  iconAction,
  hideIcon,
  extraClassNames,
  iconHasMargin,
  iconTooltip,
}) => {
  const icon = !hideIcon && (
    <div
      onClick={iconAction}
      className={classNames('cursorPointer', iconHasMargin ? 'closeIconWrapperWithMargin' : 'closeIconWrapper')}>
      <Icon icon="fa-close" testId="deleteIcon" tooltip={iconTooltip} />
    </div>
  );
  return iconHasMargin ? (
    <div>
      {icon}
      <div className={classNames('removableBlockWrapper', extraClassNames)} data-testid={testId}>
        <>{children}</>
      </div>
    </div>
  ) : (
    <div className={classNames('removableBlockWrapper', extraClassNames)} data-testid={testId}>
      {icon}
      <>{children}</>
    </div>
  );
};

interface CollapsibleFormGroupProps {
  testId?: string;
  children: React.ReactNode;
  deleteIconAction: () => void;
  hideDeleteIcon?: boolean;
  extraClassNames?: string;
  labelExtraClassNames?: string;
  isCollapsed: boolean;
  toggleCollapse: () => void;
  label: string;
  isLast?: boolean;
}

export const CollapsibleFormBlock: React.FunctionComponent<CollapsibleFormGroupProps> = ({
  testId,
  children,
  deleteIconAction,
  hideDeleteIcon,
  extraClassNames,
  labelExtraClassNames,
  isCollapsed,
  toggleCollapse,
  label,
  isLast,
}) => {
  const icon = (
    <div className="cursorPointer closeIconWrapper">
      <Icon onClick={toggleCollapse} icon={isCollapsed ? 'fa-chevron-right' : 'fa-angle-down'} testId="toggleIcon" />
      {hideDeleteIcon ? null : <Icon onClick={deleteIconAction} icon="fa-close ml10" testId="deleteIcon" />}
    </div>
  );

  return (
    <div
      className={classNames('collapsibleBlockWrapper grayBorder', extraClassNames, { bottomBorderRadius: isLast })}
      data-testid={testId}>
      {icon}
      <div
        className={classNames('text-bold cursorPointer text-underline', labelExtraClassNames, { mb20: !isCollapsed })}
        onClick={toggleCollapse}>
        {label}
      </div>
      <div className={isCollapsed ? 'collapsibleContent' : ''}>{children}</div>
    </div>
  );
};

export const DisplayOnlyFormElementWrapper = ({ extraClassNames, testId, children }) => (
  <div className={classNames(extraClassNames)} data-testid={testId}>
    {children}
  </div>
);
