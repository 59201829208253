import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { ChartDrawService } from '@/services/chartDraw.service';
import { useResizeWatcher } from '@/core/hooks/useResizeWatcher.hook';
import { notifyIfChanged } from '@/core/utilities';
import { TREND_VIEWS, TrendColorMode } from '@/trendData/trendData.constants';
import { CapsuleTimeLegend } from '@/trend/CapsuleTimeLegend.organism';
import { cleanseTrendProps, provideVisualizationData } from '@/annotation/ckEditorPlugins/components/content.utilities';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { CallbackRef, HTMLElementOrNull } from '@/utilities.types';
import {
  ChartInfoProps,
  ChartServiceProps,
  ChartViewActions,
  ChartViewConfiguration,
  ChartViewData,
} from '@/trend/chart.types';
import { ColorByCapsulePropertyLegend } from '@/trend/trendViewer/ColorByCapsulePropertyLegend.molecule';

interface ChartViewProps {
  actions: ChartViewActions;
  configuration: ChartViewConfiguration;
  data: ChartViewData;
}

export const ChartView: React.FunctionComponent<ChartViewProps> = (props) => {
  const [chartNode, setChartNode] = useState<HTMLElementOrNull>(null);
  const chartRef: CallbackRef = useCallback((node: HTMLElementOrNull) => {
    setChartNode(node);
  }, []);
  const serviceRef = useRef<ReturnType<typeof ChartDrawService>>();
  const oldProps = useRef<typeof props>();
  const callbacksMap = useRef([
    {
      property: 'data.items',
      callback: () => serviceRef.current?.updateItems(),
    },
    {
      property: 'configuration.view',
      callback: () => serviceRef.current?.destroyChart(),
    },
    {
      property: 'data.annotatedItemIds',
      callback: () => serviceRef.current?.updateAnnotatedItemsIds(),
    },
    {
      property: 'data.selectedRegion',
      callback: () => serviceRef.current?.updateSelectedRegion(),
    },
    {
      property: 'configuration.isPickingMode',
      callback: () => serviceRef.current?.updateIsPickingMode(),
    },
    {
      property: 'data.selectedTimezone',
      callback: () => serviceRef.current?.updateSelectedTimezone(),
    },
    {
      property: 'data.breaks',
      callback: () => serviceRef.current?.updateBreaks(),
    },
    {
      property: 'configuration.isDimmed',
      callback: () => serviceRef.current?.updateIsDimmed(),
    },
    {
      property: 'data.cursorChange',
      callback: () => serviceRef.current?.syncCursors(),
    },
    {
      property: 'configuration.labelDisplayConfiguration',
      callback: () => serviceRef.current?.syncTrendStore('labelDisplayConfiguration'),
    },
    {
      property: 'configuration.showGridlines',
      callback: () => serviceRef.current?.syncTrendStore('showGridlines'),
    },
    {
      property: 'configuration.showCapsuleLaneLabels',
      callback: () => serviceRef.current?.syncTrendStore('showCapsuleLaneLabels'),
    },
    {
      property: 'configuration.customizationMode',
      callback: () => serviceRef.current?.syncTrendStore('customizationMode'),
    },
    // defined groups - if ANY of these changed, call the function
    {
      property: ['configuration.trendStart', 'configuration.trendEnd', 'configuration.capsuleTimeOffsets'],
      callback: () => serviceRef.current?.updateXExtremes(),
    },
    {
      property: ['configuration.autoUpdateMode', 'configuration.capsuleTimeColorMode', 'data.capsuleEditingId'],
      callback: () => serviceRef.current?.syncAutoUpdate(),
    },
  ]);

  useEffect(() => {
    if (chartNode && _.isUndefined(serviceRef.current)) {
      const infoProps: ChartInfoProps = { ...props.data, ...props.configuration };
      serviceRef.current = ChartDrawService({ ...infoProps, ...props.actions } as ChartServiceProps, chartNode);
      serviceRef.current.updateItems();
      // It is important that the initial state of props be captured here so that on the next render notifyIfChanged
      // will be invoked, otherwise an update may be missed (CRAB-38890)
      oldProps.current = props;
      if (props.configuration.isInTopic) {
        serviceRef.current.syncCursors();
      }
    }
  }, [chartNode, props]);

  // This code is invoked on every render and order of operations is important. First compare current props to old
  // props and notify the chart of any changes, then copy the current props to old.
  if (!_.isUndefined(oldProps.current)) {
    const infoProps: ChartInfoProps = { ...props.data, ...props.configuration };
    serviceRef.current?.updateScope(infoProps);
    // check diffs
    notifyIfChanged(oldProps.current, props, callbacksMap.current);
  }
  if (!_.isUndefined(serviceRef.current)) {
    oldProps.current = props;
  }
  useResizeWatcher({
    element: chartNode,
    callOnLoad: false,
    callback: () => serviceRef.current?.reflowTrend(),
  });

  if (headlessRenderMode()) {
    provideVisualizationData({
      ...cleanseTrendProps(props),
      visualization: Visualization.TREND,
    });
  }

  return (
    <div className="flexFill flexRowContainer">
      <div
        className="chart sq-chart chartView flexFill mr10"
        ref={chartRef}
        id="chartDisplay"
        data-testid="chartDisplay"
      />
      {props.data.sqTrendStoreData.view === TREND_VIEWS.CAPSULE && (
        <CapsuleTimeLegend
          conditions={props.data.sqTrendConditionStoreData.items}
          capsules={props.data.capsules}
          sortedColumn={props.data.sortedColumn}
          view={props.data.sqTrendStoreData.view}
          capsuleTimeColorMode={props.configuration.capsuleTimeColorMode}
        />
      )}
      {(props.data.sqTrendStoreData.view === TREND_VIEWS.CALENDAR ||
        props.data.sqTrendStoreData.view === TREND_VIEWS.CHAIN) &&
      props.data.sqTrendStoreData?.trendColorSettings?.colorMode !== TrendColorMode.Item &&
      (props.data.sqTrendCapsuleStoreData?.usedCapsulePropertyValues?.length ?? 0) > 0 ? (
        <ColorByCapsulePropertyLegend
          usedCapsulePropertyValues={props.data.sqTrendCapsuleStoreData.usedCapsulePropertyValues}
          trendCapsulePropertyColors={props.data.sqTrendCapsuleStoreData.trendCapsulePropertyColors}
          trendColorSettings={props.data.sqTrendStoreData.trendColorSettings}
        />
      ) : null}
    </div>
  );
};
