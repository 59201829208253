import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { sqDurationStore } from '@/core/core.stores';
import _ from 'lodash';
import { OutputType } from '@/tools/selfOrganizingMaps/selfOrganizingMaps.constants';
import { AnyProperty } from '@/utilities.types';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';
import { Capsule } from '@/utilities/datetime.constants';

export class SelfOrganizingMapsStore extends BaseToolStore {
  static readonly storeName = 'sqSelfOrganizingMapsStore';
  type = TREND_TOOLS.SELF_ORGANIZING_MAPS;
  parameterDefinitions = {
    condition: {
      predicate: ['name', 'condition'],
    },
    restrictToCondition: {
      predicate: ['name', 'restrictToCondition'],
    },
    inputSignals: {
      predicate: (parameter: { name: string }) =>
        parameter.name !== 'condition' && parameter.name !== 'restrictToCondition',
      multiple: true,
    },
  };

  initialize() {
    this.state = this.immutable({
      ...BASE_TOOL_COMMON_PROPS,
      windowStart: sqDurationStore.displayRange.start.valueOf(),
      windowEnd: sqDurationStore.displayRange.end.valueOf(),
      window: this.monkey(['windowStart'], ['windowEnd'], (startTime, endTime) => ({ startTime, endTime })),
      modelPropertiesExpanded: false,
      color: '',
      anomalyThreshold: 95,
      sigma: 1.0,
      learningRate: 0.5,
      gridDimension: '',
      numIterations: '',
      randomOrder: false,
      griddingPeriod: { value: 15, units: 'min' },
      outputType: OutputType.CONDITION,
    });
  }

  get inputSignals() {
    return this.state.get('inputSignals');
  }

  get condition() {
    return this.state.get('condition');
  }

  get restrictToCondition() {
    return this.state.get('restrictToCondition');
  }

  get window() {
    return this.state.get('window');
  }

  get color(): string {
    return this.state.get('color');
  }

  get modelPropertiesExpanded(): boolean {
    return this.state.get('modelPropertiesExpanded');
  }

  get anomalyThreshold(): number {
    return this.state.get('anomalyThreshold');
  }

  get sigma(): number {
    return this.state.get('sigma');
  }

  get learningRate(): number {
    return this.state.get('learningRate');
  }

  get gridDimension(): number {
    return this.state.get('gridDimension');
  }

  get numIterations(): number {
    return this.state.get('numIterations');
  }

  get randomOrder(): boolean {
    return this.state.get('randomOrder');
  }

  get griddingPeriod() {
    return this.state.get('griddingPeriod');
  }

  get outputType() {
    return this.state.get('outputType');
  }

  dehydrate() {
    return this.state.serialize();
  }

  rehydrate(dehydratedState: AnyProperty) {
    this.state.merge(dehydratedState);
  }

  setTrainingWindow(payload: { window: Capsule }) {
    this.state.set('windowStart', _.get(payload.window, 'startTime'));
    this.state.set('windowEnd', _.get(payload.window, 'endTime'));
  }

  setModelPropertiesExpanded(payload: { modelPropertiesExpanded: boolean }) {
    this.state.set('modelPropertiesExpanded', payload.modelPropertiesExpanded);
  }

  setAnomalyThreshold(payload: { anomalyThreshold: number }) {
    this.state.set('anomalyThreshold', payload.anomalyThreshold);
  }

  setSigma(payload: { sigma: number }) {
    this.state.set('sigma', payload.sigma);
  }

  setLearningRate(payload: { learningRate: number }) {
    this.state.set('learningRate', payload.learningRate);
  }

  setGridDimension(payload: { gridDimension: number }) {
    this.state.set('gridDimension', payload.gridDimension);
  }

  setNumIterations(payload: { numIterations: number }) {
    this.state.set('numIterations', payload.numIterations);
  }

  setRandomOrder(payload: { randomOrder: boolean }) {
    this.state.set('randomOrder', payload.randomOrder);
  }

  setGriddingPeriod(payload: { period: ValueWithUnitsItem }) {
    this.state.set('griddingPeriod', _.pick(payload.period, ['value', 'units']));
  }

  setoutputType(payload: { outputType: OutputType }) {
    this.state.set('outputType', payload.outputType);
  }

  modifyConfigParams(config: any) {
    return _.omit(config, ['modelPropertiesExpanded']);
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    SOM_SET_TRAINING_WINDOW: this.setTrainingWindow,
    SOM_SET_MODEL_PROPERTIES_EXPANDED: this.setModelPropertiesExpanded,
    SOM_SET_ANOMALY_THRESHOLD: this.setAnomalyThreshold,
    SOM_SET_SIGMA: this.setSigma,
    SOM_SET_LEARNING_RATE: this.setLearningRate,
    SOM_SET_GRID_DIMENSION: this.setGridDimension,
    SOM_SET_NUM_ITERATIONS: this.setNumIterations,
    SOM_SET_RANDOM_ORDER: this.setRandomOrder,
    SOM_SET_GRIDDING_PERIOD: this.setGriddingPeriod,
    SOM_SET_OUTPUT_TYPE: this.setoutputType,
    SOM_SET_COLOR: ({ color }: { color: string }) => {
      this.state.set('color', color);
    },
  };
}
